<template>
  <div>
      <Form @submit="submit" v-slot="errors" class="input-fields" v-if="!registered" >
        <h1 v-if="isEdit"
          class="Headline is-size-5"
          v-t="'BasicRegistrationTitle'"
        />
        <strong
          v-if="!isEdit"
          class="Headline is-size-5 d-block"
          v-t="'registration.recipient.title'"
        />
        <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" :type="text" :errors="errors"/>
        <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel" :rules="`required|max:20`"  :type="text" :errors="errors"/>
        <input-select-component :fieldName="fieldNames.country" :options="countries" :fieldLabel="countryFieldLabel" />
        <div v-if="termsOfUseCheckboxRequired" class="form-checkboxes">
          <input-checkbox-component
            :fieldLabel="$t('form.terms')"
            :fieldName="fieldNames.terms"
            :errors="errors.errors[fieldNames.terms]"
          >
            <terms-of-use-and-privacy-policy-links />
          </input-checkbox-component>
        </div>
        <button
          class="Button form-button d-block"
          :class="{ 'is-grey': !errors.meta.valid }"
          :disabled="!errors.meta.valid"
          v-text="$t(nextLabel)"
        />
      </Form>
      <p v-if="!isEdit">
          {{ $t('form.alreadyAccount') }} <link-router
            path="home"
          >{{ $t('form.loginNow') }}</link-router>
      </p>
    <regapp-feedback v-if="registered">
      <template #body>
        <div v-html="translateWithMymedelString(`${responseMessageKey}`)" />
      </template>

      <template #footer>
        <br>
        <link-router
          class-names="Link d-i-block text-grey mt-1"
          path="dashboard"
        >
          {{ $t('links.toDashboard.label') }}
        </link-router>
      </template>
    </regapp-feedback>
    <sweet-modal icon="warning" ref="modalError">
      <div v-html="$t('registration.messages.internal:error')" />
    </sweet-modal>
    <validation-error-message :errors="errors" ref="validationErrorMessage" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import registrationSteps from '@/mixins/registrationForm.js'
import TermsOfUseAndPrivacyPolicyLinks from '../components/partials/TermsOfUseAndPrivacyPolicyLinks.vue'
import { Form } from 'vee-validate'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'
import InputCheckboxComponent from '@/components/partials/InputCheckboxComponent.vue'
export default {
  name: 'CompleteRegistrationBasic',

  props: {
    submitSuccess: {
      type: Boolean,
      default: false
    },

    isEdit: {
      type: Boolean,
      default: false
    }
  },

  mixins: [ registrationSteps, localeMessages ],

  components: {
    ValidationErrorMessage,
    TermsOfUseAndPrivacyPolicyLinks,
    InputComponent,
    InputSelectComponent,
    Form,
    InputCheckboxComponent
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'isBasic',
      'fullName',
      'countryString'
    ])
  },

  data () {
    return {
      errors: {},
      form: {},
      country: null,
      confirmations: {
        terms: false
      },
      registered: false,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      email: '',
      fieldNames: {
        firstName: 'firstName',
        lastName: 'lastName',
        terms: 'terms',
        country: 'country'
      }
    }
  },

  mounted () {
      let idTokenInfo = this.$auth0.idTokenClaims.value || {}
      let aadInfo = idTokenInfo['microsoft.com/aad/profile'] || {}
      this.email = idTokenInfo['email'] ? idTokenInfo['email'] : aadInfo.upn

      this.country = this.countries.find(i => i.code === this.form.country)
      this.language = this.$i18n.locale ? this.$i18n.locale : 'en'
  },

  methods: {
    ...mapActions({
      loadUserProfile: 'user/getUserFromDB',
      loadServiceClaims: 'user/getServiceClaims'
    }),
    async submit (values) {
        var success = await this.putUserProfile(this.getRequestObject('basic', values), false)
        if (success) {
          if (this.$route.query.state) {
            window.location.replace(`https://${process.env.VUE_APP_AUTH0_DOMAIN}/continue?state=${this.$route.query.state}`)
          } else {
            this.responseMessageKey = `registration.messages.registrationCompleted`
            this.registered = true
            this.scrollUp()
            await this.loadUserProfile()
            await this.loadServiceClaims()
          }
        }
    },

    getOptionValues (translationKey, asObject = false) {
      const entries = this.$tm(translationKey)
      return Object.keys(entries).map(key => {
        return asObject ? {
          code: key,
          label: entries[key]
        } : entries[key]
      })
    },

    getRequestObject (registrationType, values) {
      return Object.assign(this.form, {
        registrationType,
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        country: values[this.fieldNames.country].code,
        agreedToTermsAndConditions: values[this.fieldNames.terms],
        dateOfRegistration: new Date(),
        email: this.email
      })
    }
  }
}

</script>
